import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Paekabu = (props) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    const fetchImages = async () => {
      const res = await fetch("http://localhost:3333/images");
      const images = await res.json();
      setImages(images);
    };
    fetchImages();
  }, []);

  return (
    <>
      <div>Paekabu</div>
      <Link to="/">Back to home</Link>
      <div>
        {images.map((i) => {
          return <img src={i.url} width={200} height={200} />;
        })}
      </div>
    </>
  );
};

export default Paekabu;
